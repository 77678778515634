import * as _ from 'lodash'
import { FormStrategy } from './form-strategy'
import { FIELDS } from '../../constants/roles'
import { RegistrationFieldPreset } from '@wix/forms-common'
import { CRM_TYPES, CUSTOM_FIELD } from '../../constants/crm-types-tags'
import { getInputValue } from '../input-value'
import { getFieldType, isNumber } from '../viewer-utils'
import { siteStore } from '../stores/site-store'
import { sanitizePII } from '@wix/bi-logger-sanitizer/dist/src/lib/sanitizers'
import { RegistrationError } from '../errors'
import { SubmitFormResponse } from '../../types/domain-types'

const PASSWORD_LENGTH = { MIN: 4, MAX: 15 }
const PREVIEW_MODE = 'Preview'

const getFields = $w => {
  const email: any = loginEmailField($w)
  const password: any = passwordField($w)
  const joinTheCommunityCheckbox: any = joinTheCommunityCheckboxField($w)
  return { email, password, joinTheCommunityCheckbox }
}

const loginEmailField = ($w): any =>
  _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL}`)).first()
const passwordField = ($w): any => _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD}`)).first()
const linkLoginField = ($w): any =>
  _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG}`)).first()
const joinTheCommunityCheckboxField = ($w): any =>
  _($w(`@${FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY}`)).first()

const submitFields = fields =>
  fields.filter(field => _.get(field, 'connectionConfig.fieldType') !== 'password')

const IGNORED_FIELDS_WITHOUT_EMAIL = {
  [RegistrationFieldPreset.REGISTRATION_FORM_PASSWORD]: true,
  [RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: true,
  [RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: true,
}

const valueHandlerByType = {
  DatePicker: ({ value }) => value,
}

const getFieldValue = field => {
  const fieldType = getFieldType(field)
  const fieldValue = valueHandlerByType[fieldType]
    ? valueHandlerByType[fieldType](field)
    : getInputValue(field)
  return isNumber(field) ? +fieldValue : fieldValue
}

const buildContactInfo = validFields => {
  const contactInfo = {
    phones: [],
    emails: [],
  }

  validFields.forEach(field => {
    const { crmType, customFieldId, customFieldName } = field.connectionConfig
    const fieldValue = getFieldValue(field)
    switch (crmType) {
      case CRM_TYPES.EMAIL:
        contactInfo.emails.push(fieldValue)
        break
      case CRM_TYPES.PHONE:
        contactInfo.phones.push(fieldValue)
        break
      case CUSTOM_FIELD:
        if (customFieldId) {
          contactInfo[customFieldName] = fieldValue
        }
        break
      default:
        contactInfo[crmType] = fieldValue
    }
  })
  return contactInfo
}

export class RegistrationFormStrategy extends FormStrategy {
  firstInitialization = {
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: true,
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: true,
  }

  static isEnabled($w) {
    const { email, password } = getFields($w)
    return email && password
  }

  constructor(submitArgs) {
    super(submitArgs)
  }

  registerCustomValidation(submitArgs) {
    this.registerPasswordValidation(submitArgs.$w)
    this.registerLoginLink(submitArgs)
  }

  registerPasswordValidation($w) {
    const password = passwordField($w)

    if (!password) return

    password.onCustomValidation((value, reject) => {
      if (this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]) {
        this.firstInitialization[FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD] = false
        return
      }

      if (value.length < PASSWORD_LENGTH.MIN || value.length > PASSWORD_LENGTH.MAX) {
        //tslint:disable-line
        reject(
          siteStore.t('registrationForm.passwordLimitError', {
            min: PASSWORD_LENGTH.MIN,
            max: PASSWORD_LENGTH.MAX,
          })
        )
      }
    })
  }

  registerLoginLink({ $w, wixUsers, wixWindow }) {
    const loginLink = linkLoginField($w)
    if (!loginLink) return
    loginLink.onClick(() =>
      wixUsers.promptLogin({ mode: 'login' }).then(() => wixWindow.lightbox.close())
    )
  }

  async execute({ attachments, fields, viewMode, formId }): Promise<SubmitFormResponse> {
    if (viewMode === PREVIEW_MODE) {
      return Promise.resolve({})
    }

    const { $w, wixUsers, wixWindow } = this.submitArgs
    const { email, password, joinTheCommunityCheckbox } = getFields($w)
    const privacyStatus =
      joinTheCommunityCheckbox && joinTheCommunityCheckbox.checked ? 'PUBLIC' : 'PRIVATE'
    const payload = { defaultFlow: true, privacyStatus }

    const validFields = fields.filter(
      field =>
        field.connectionConfig.crmType &&
        !_.isEmpty(getInputValue(field, attachments)) &&
        !IGNORED_FIELDS_WITHOUT_EMAIL[field.connectionConfig.fieldType]
    )

    await super.execute({ attachments, fields: submitFields(validFields), formId })

    const contactInfo = buildContactInfo(validFields)
    payload['contactInfo'] = contactInfo

    try {
      siteStore.interactionStarted('registration')
      await wixUsers.register(email.value, password.value, payload)
      siteStore.interactionEnded('registration')
    } catch (e) {
      if (typeof e === 'string') {
        const sanitizedErrorMessage = sanitizePII(e)

        if (_.startsWith(e, 'member with email')) {
          siteStore.interactionEnded('registration') // expected exception, shouldn't be counted as error
          throw new RegistrationError('member already exists in collection', sanitizedErrorMessage)
        }

        throw new RegistrationError(sanitizedErrorMessage)
      }

      throw new RegistrationError('SDK Error', e)
    }

    wixWindow.lightbox.close()

    return super.execute({
      attachments,
      fields: submitFields(validFields),
      skipSendActivity: true,
      formId,
    })
  }

  postSubmission() {
    const { wixWindow } = this.submitArgs
    setTimeout(() => wixWindow.lightbox.close(), 750)
    return Promise.resolve()
  }
}
